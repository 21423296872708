/* Provide sufficient contrast against white background */

a {
  color: #55B2B8;
}

code {
  color: #E01A76;
}

h3 {
  min-height: 145px;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.hover-item-parent {
  position: relative;
  height: 168px;
  min-height: 168px;
}

.hover-item-parent>svg {
  position: absolute;
  bottom: 24px;
}

.hover-item-icon {
  position: absolute;
  left: 0px;
  margin: auto;
  width: 100% !important;
  align-content: center;
}

.hover-item-fade {
  background-color: rgba(235, 235, 235, 0.6);
  position: absolute;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
}